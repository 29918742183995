// extracted by mini-css-extract-plugin
export var hero_outer = "index-module--hero_outer--3oh33";
export var monday_platform = "index-module--monday_platform--1Knaq";
export var mondayLogoTitle = "index-module--monday-logo-title--H-eAV";
export var highlight = "index-module--highlight--vNwDy";
export var list = "index-module--list--10KTe";
export var outer = "index-module--outer--35AUz";
export var container = "index-module--container--26A8i";
export var monday_platform_title = "index-module--monday_platform_title--2rw5O";
export var contact_us = "index-module--contact_us--3u_Uo";
export var get_in_touch = "index-module--get_in_touch--2cmz9";